.reflect-comment {
  h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.25;
  }

  .comment-item {
    display: flex;
    flex-wrap: wrap;
    &__avatar {
      width: 64px;
      padding-right: 16px;
      img {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 9999px;
      }
    }
    &__body {
      width: calc(100% - 64px);
    }
    &__content {
      width: 100%;
      padding: 16px;
      background-color: #f3f3f3;
      color: #6a6a6a;
      border-radius: 10px;
      .read-more {
        white-space: nowrap;
        font-size: 14px;
        color: #2a72de;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__author {
      font-size: 18px;
      font-weight: 600;
      color: #ed2973;
    }
    &__action {
      margin-top: 8px;
      font-size: 14px;
      color: #a6a6a6;
      span {
        font-weight: 600;
        cursor: pointer;
      }
    }
    &__show-more {
      display: flex;
      justify-content: flex-end;
      > div {
        font-size: 18px;
        cursor: pointer;
        opacity: 0.8;
        color: #11998e;
        transition: all 150ms ease-in-out 0ms;
        margin-left: 8px;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .comment-form {
    &__action {
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;
      > * {
        display:block;
        margin-left: 8px;
        margin-bottom: 0;
        padding: 10px 15px;
        background: linear-gradient(44.02deg, #ed2973 5.97%, #fe7249 94%);
        box-shadow: 0px 0px 15px rgba(237, 41, 115, 0.25);
        border-radius: 30px;
        border: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.25;
        color: #fff;
        transition: all 150ms ease-in-out 0ms;
        cursor: pointer;
        &:hover {
          transform: scale(1.01);
        }
        &:active {
          transform: translateY(2px);
        }
      }
    }
  }
  .preview-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    > div.preview-item {
      position: relative;
      width: 20%;
      padding-top: 20%;
      .close-btn {
        position: absolute;
        top: 6px;
        right: 6px;
        padding: 4px;
        line-height: 1;
        color: #fff;
        text-shadow: 0px 0px 10px black;
        cursor: pointer;
      }
    }
    img, video, .document {
      position: absolute;
      top: 4px;
      left: 4px;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      object-fit: cover;
      border: 1px dashed rgba($color: #000, $alpha: 0.2);
    }
    .document {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #000;
      cursor: pointer;
      svg {
        align-self: center;
        font-size: 56px;
      }
      p {
        margin: 0;
      }
    }
  }

  .grid-view {
    width: 100%;
    display: grid;
    gap: 4px;
    > * {
      width: 100%;
      position: relative;
      padding-top: 100%;
      > img, video, div {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .remain-image {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      background-color: rgba($color: #000, $alpha: 0.5);
      color: #fff;
    }
    // &.grid-view__one-item {
    //   // grid-template-areas: 'img1';
    //   > *:nth-child(1) {
    //     // grid-area: myArea;
    //   }
    // }
    &.grid-view__two-item {
      grid-template-columns: 50% 50%;
      > *:nth-child(1), > *:nth-child(2) {
        padding-top: 200%;
      }
    }
    &.grid-view__three-item {
      grid-template-areas: 
        "img1 img2"
        "img1 img3";
      > *:nth-child(1) {
        grid-area: img1;
        padding-top: 200%;
      }
      > *:nth-child(2) {
        grid-area: img2;
      }
      > *:nth-child(3) {
        grid-area: img3;
      }
    }
    &.grid-view__four-item {
      grid-template-areas: 
        "img1 img2"
        "img3 img4";
      > *:nth-child(1) {
        grid-area: img1;
      }
      > *:nth-child(2) {
        grid-area: img2;
      }
      > *:nth-child(3) {
        grid-area: img3;
      }
      > *:nth-child(4) {
        grid-area: img4;
      }
    }
    &.grid-view__five-item {
      grid-template-areas: 
        "img1 img1 img1 img2 img2 img2"
        "img3 img3 img4 img4 img5 img5";
      > *:nth-child(1) {
        grid-area: img1;
      }
      > *:nth-child(2) {
        grid-area: img2;
      }
      > *:nth-child(3) {
        grid-area: img3;
      }
      > *:nth-child(4) {
        grid-area: img4;
      }
      > *:nth-child(5) {
        grid-area: img5;
      }
    }
  }

  .gallery-wrapper {
    padding: 32px;
    position: fixed;
    inset: 0;
    background-color: rgba($color: #000000, $alpha: 0.2);
    z-index: 100;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .slick-slider {
      width: 100%;
    }
    .gallery-item {
      display: flex !important;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 64px);
    }
    img, video {
      max-width: 100%;
      min-width: 200px;
      max-height: calc(100vh - 64px);
    }
    .close-btn {
      position: fixed;
      top: 12px;
      right: 12px;
      font-size: 32px;
      cursor: pointer;
    }
  }
}
