.left_project {
    position: absolute;
    top: 0;
    left: 0;
    width: 33%;

    .construction {
        .container_ci {
            max-width: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 728px;
            margin: 1rem;
            @media (max-width: 1400px) {
                height: 68vh;
            }
            span {
                color: blue;
            }

            .name_ci {
                padding: 1rem;

                h3 {
                    font-size: 2rem;
                    color: #4e4e4e;
                    margin-bottom: 15px;
                    font-weight: 600;
                    line-height: 1.2;
                    text-align: center;
                }

                p {
                    font-size: 16px;
                    color: rgb(78, 78, 78);
                }
            }

            .content_ci {
                padding: 0 1rem;


                .value_ci {
                    p {
                        font-weight: 600;
                        color: #323232;
                    }
                }
            }

            .address_ci {
                padding: 1rem;
                font-size: 1.1rem;
                font-weight: 500;
            }

            .slide {
                img {
                    width: 100%;
                    max-height: 300px;
                    padding: 1rem;
                    border-radius: 25px;
                }
            }
        }

        .project__info {

            .header__info {
                margin: 0 2rem;
                background-color: #11998e;
                border-radius: 5px;

                p {
                    color: #fff;
                    font-weight: 600;
                    display: inline-block;
                    margin: 0;
                }

                button {
                    margin-top: 5px;
                }
            }

            .list__info {
                overflow-y: scroll;
                max-height: 713px;

                .detail__info {
                    .avatar_ci {
                        img {
                            width: 100%;

                        }
                    }

                    .title_name {
                        p {
                            text-align: center;
                            font-size: 24px;
                            font-weight: 700;
                            padding: 5px;
                            margin: 0;
                        }

                    }

                    table {
                        width: 100%;

                        th,
                        td {
                            border: 1px solid #000;
                            padding: 5px;
                            width: auto;
                        }
                    }

                }
            }

        }

        .construction_title {
            h2 {
                text-align: center;
                font-size: 1.4em;
                font-weight: 700;
                font-family: "Roboto";
                color: #0055ab;
                position: relative;
                margin-bottom: 0;
            }

        }


        .tab_ {
            margin: 0 2rem 1rem 2rem !important;
            background-color: aliceblue;

            .col-6 {
                border-bottom: 1px solid gray;
            }

            p {
                text-align: center;
                font-weight: 700;
                margin-top: 1rem;
            }

            .active {
                border-bottom: 3px solid #11998e;
            }
        }
    }

    .container {
        padding-right: 0;

        .length {
            display: flex;

            p {
                background-color: #11998e;
                border-radius: 15px;
                padding: 10px 15px;
                color: #fff;
            }

            span {
                color: red;
                font-size: 18px;
                font-weight: 700;
            }
        }

        .list_item {
            max-height: 648px;
            overflow-y: auto;
            @media (max-width: 1400px) {
                height: 54vh;
            }
            .project_item {
                position: relative;
                width: 50%;
                padding-right: 1rem;
                padding-bottom: 2rem;
                display: inline-block;

                .project_img {
                    width: 100%;
                    //max-height: 11rem;
                    //width: 17.1875rem;
                    height: 11rem;
                    border-radius: 10px;
                    box-shadow: 2px 2px 5px gray;
                    background: rgb(89, 89, 89);
                    background: linear-gradient(0deg, rgba(89, 89, 89, 1) 0%, rgba(46, 46, 46, 0) 100%);
                }

                .project_content {
                    cursor: pointer;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    width: 95%;
                    bottom: 30px;
                    background: linear-gradient(0deg, #595959 0%, rgba(46, 46, 46, 0) 100%);
                    color: #fff;
                    border-radius: 10px;

                    .project_title {
                        position: absolute;
                        width: 100%;
                        top: 4rem;
                        padding: 1rem;
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        max-height: 64px;
                        p {
                            margin-bottom: 0;
                        }
                    }

                    .project_place {
                        position: absolute;
                        bottom: 0;
                        padding-left: 1rem;
                        font-size: 12px;
                        // white-space: nowrap;
                        // overflow: hidden;
                    }
                }
            }
        }



    }

    .pagination__ {
        float: right;
    }

    .sort__ {
        position: relative;
        z-index: 11;
    }
}

.left-map-search {
    position: absolute;
    z-index: 11;
    width: max-content;
    border-radius: 10px;
    box-shadow: 1px 1px 3px gray;
    .search_item {
        background-color: #fff;
        padding: 0.5rem;
        border-radius: 10px;  

    }
}