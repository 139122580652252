.auth-container {
  .icon-title {
    font-size: 3rem;
  }
  
  .form-control {
    border-radius: 15px !important;
  }
  
  .title {
    color: #2A72DE !important;
    font-family: 'Inter';
    font-weight: 600 !important;
  }
  
  .forgot-password {
    color: #11998e !important;
  }
  
  .icon-input {
    position: absolute;
    top: 10px;
    left: 25px;
  }
  
  .form-control {
    padding-left: 30px !important;
  }
  .title-logo {
    margin-left: 10px;
    align-items: center;
  }
  .remember {
    margin-left: 10px !important;
  }
  .form-check-label {
    color: #959595;
    font-size: 14px;
  }
  .form-check-input{
    margin-top: 0.4rem;
  }
  .forgot-password {
    color: #2A72DE;
    font-size: 14px;
    cursor: pointer;
  }
  .btn-submit {
    border-radius: 15px !important;
    background: linear-gradient(44.02deg, #ED2973 5.97%, #FE7249 94%);
  }
  .forgot {
    margin-top: 80px;
  }
  
  .close {
    cursor: pointer;
    float: right;
    font-size: 1rem !important;
  }
  .tab__title {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    align-items: center;
  }
  
  .tab__title span {
    font-size: 14px;
    color: #2A72DE;
    padding: 5px 0px;
    display: block;
    font-weight: 600;
  }
  
  .tab__title span.is_active {
    position: relative;
  }
  
  .tab__title span.is_active::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    top: 100%;
    left: 0;
    background-color: #2A72DE;
    transition: 0.2s;
  }
  
  .tab__title span:first-child {
    margin-right: 30px;
  }
  @media (max-width: 820px) {
    .left-register {
      display: none;
    }
  }
}
@media (max-width: 991px) {
  .left-register {
    display: none;
  }
}