.records-management {
  .table {
    tbody {
      tr {
        td {
          padding: 0.5rem;
        }
      }
    }
  }

  .planning-records-search {
    border: 1px solid #c5c5c5;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .title-records {
      background: #11998e;
      padding: 10px 15px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      span {
        color: #ffffff;
        font-weight: 500;
      }
    }
  }

  .planning-records-result {
    .planning-records-title {
      color: #747474;
      cursor: pointer;
      padding: 10px 15px;

      .active {
        color: #e43c5c;
      }
    }

    .records-table {
      img {
        height: 115px;
        object-fit: cover;
      }

      .title {
        text-decoration: none;
        color: #333;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
      }

      p,
      span,
      .icon {
        font-size: 14px;
      }

      a {
        text-decoration: none;
        color: #3E81BB;
      }
    }

    .records-list {
      table {
        display: table;

        tbody {
          tr {
            th {
              a {
                color: #333;
                font-size: 0.875rem;
                text-decoration: none;
                cursor: pointer;
              }
            }

            td {
              a {
                font-size: 0.875rem;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  .list-footer {
    border-top: 1px solid #b6b3b3;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .text-result {
      color: #c22e3c;
      font-weight: 600;
    }
  }

  .records-management-detail {
    .title {
      padding: 10px 0;

      .icon-back {
        cursor: pointer;
        color: #e43c5c;
      }

      h5 {
        color: #11998e;
      }
    }

    .nav-tabs {
      border-bottom: 2px solid #11998e;

      .nav-item {
        background-color: #eeeeee;

        .nav-link {
          color: #333;
          border-bottom-color: #11998e;
          border-radius: 0;

          &.active {
            color: #fff;
            background-color: #11998e;
            border-color: #11998e;
            border-radius: 0;
          }
        }
      }
    }

    .tab-content {
      .tab-pane {
        border-bottom: 2px solid #11998e;

        .table {
          thead {
            tr {
              th {
                border-top: 2px solid #dee2e6;
                padding: 8px;
              }
            }
          }

          tbody {
            tr {
              td {
                cursor: pointer;

                &.detail {
                  cursor: auto;

                  .action {
                    a {
                      text-decoration: none;
                      font-size: 0.875rem;
                      color: #3E81BB;
                    }
                  }
                }
              }
            }
          }
        }

        .records-table {
          img {
            height: 125px;
            object-fit: cover;
          }

          .title {
            text-decoration: none;
            color: #333;
            font-size: 0.875rem;
          }

          p,
          span,
          .icon {
            font-size: 0.8rem;
          }

          a {
            text-decoration: none;
            color: #3E81BB;
          }
        }

        .item-detail {
          font-size: 13px;

          label {
            font-size: 1.2em;
            font-weight: bold;
          }
        }
      }

      .pathnode {
        padding: 0px 8px;
        background: none;
        border: none;
        color: black;

        &:hover {
          color: green;
        }

        &:focus {
          box-shadow: none;
        }

        &:active {
          box-shadow: none !important;
          background: transparent;
          color: #000;
        }
      }
    }
  }

  .hidden-preview-file {
    position: absolute;
    top: 62px;
    right: 24px;
    padding: 11px 5px;
    background: #f7a364;
    cursor: pointer;

    &:hover {
      background: #11998e;
    }
  }
}

@media only screen and (max-width: 375px) {
  .records-management-detail {
    .nav-tabs {
      .nav-item {
        width: 100%;
      }
    }
  }
}

.wrapper {
  width: 90%;
  display: flex;
  align-items: stretch;
  margin: 0 auto;
  padding-top: 1px;

  #leftPanel {
    width: 25%;
    min-height: 100%;
    font-family: Roboto;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    width: 100%;
  }
}