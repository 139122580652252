@media (max-width: 767px) {
  .main-nav {
    height: 44px;
  }

  .navbar-toggler {
    margin-left: -15px;
    margin-top: 3px;
  }

  #navbarNav {
    position: absolute;
    z-index: 99999;
    background-color: #11998e;
    border: solid 1px #CCCCCC;
    margin-left: 97px;
    top: 62px;
    width: 72%;
    padding: 10px;

    .btn-auth {
      color: #000;
      background-color: #fff;
      font-weight: 600;
      width: 105px;
    }
  }

  .header-container .navbar ul.navbar-nav li.nav-item.padding-left-menu {
    padding-left: 0px !important;
  }

  .main-nav ul li ul.level1 {
    width: 100% !important;
  }

  .nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .level1_mobile {
    max-width: 100% !important;
    width: 100% !important;

  }

  .header-container .navbar ul.navbar-nav li.nav-item.dropdown .dropdown-menu {
    margin-top: -0.3225rem
  }

  // a {
  //   background-color: #038FF4;
  // }

  #slider-home-page {
    display: none;
  }

  #home-banner {
    display: none;
  }

  #mobile-account-link {
    display: block;
  }

  .container {
    max-width: 100% !important;
  }

  .planning-announcement .header-doing .input-search {
    width: 150px !important;
  }

  #map-tool-panel-right {
    top: -50px;
    right: 100px;
    z-index: 9999;
  }

  #map-tool-list {
    z-index: 9999;
    top: 0px;
    width: 318px !important;
  }

  #right-panel-tool-filter-box {
    top: 90px;
    right: -10px;
    width: 320px;
    border-radius: 1px;
  }

  #map-tool-panel-right-search {
    display: none;
  }


  #map-tool-panel-right-base-map .select-base-map {
    left: 10px;
  }

  #planning-infomation-small-popup {
    right: -182px;
    top: 50px;
  }

  #map-tool-panel-right-search-result {
    position: relative;
    top: 60px !important;
    left: -40px !important;
    z-index: 1 !important;
  }

  #map-tool-panel-right-search-result form {
    width: 336px !important;
    z-index: 1 !important;
  }

  #map-tool-panel-right-search-result .map__autocomplete-container {
    width: 336px !important;
    left: 0px !important;
    top: 42px !important;
  }

  #map-tool-panel-right-search-result-action {
    width: 336px !important;
    z-index: 1 !important;
  }

  #map-tool-panel-right-search-result-action img {
    display: inline-block !important;
    z-index: 1 !important;
  }

}

@media screen and (max-width: 820px) and (min-width: 667px) {
  #navbarNav {
    position: absolute;
    z-index: 99999;
    background-color: #11998e;
    border: solid 1px #CCCCCC;
    margin-left: 378px;
    top: 62px;
    width: 40%;
    padding: 10px;

    .btn-auth {
      color: #000;
      background-color: #fff;
      font-weight: 600;
      width: 105px;
    }
  }
}

@media screen and (max-width: 667px) and (min-width: 520px) {
  #navbarNav {
    position: absolute;
    z-index: 99999;
    background-color: #11998e;
    border: solid 1px #CCCCCC;
    margin-left: 462px;
    top: 62px;
    width: 33%;
    padding: 10px;

    .btn-auth {
      color: #000;
      background-color: #fff;
      font-weight: 600;
      width: 105px;
    }
  }
}

@media screen and (max-width: 1023px) and (min-width: 820px) {
  #navbarNav {
    position: absolute;
    z-index: 99999;
    background-color: #11998e;
    border: solid 1px #CCCCCC;
    margin-left: 469px;
    top: 62px;
    width: 32%;
    padding: 10px;

    .btn-auth {
      color: #000;
      background-color: #fff;
      font-weight: 600;
      width: 105px;
    }
  }
}

@media (max-width: 991px) {
  .left-register {
    display: none;
  }
}

@media (min-width: 1024px) {
  .btn-authen {
    display: none;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .content__right {
    margin-left: 6rem !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1023px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: column !important;
  }

  .is_desktop {
    display: none;
  }

  #navbarNav {
    margin-left: 571px;
    width: 32%;

    .btn-auth {
      width: 115px;
    }
  }
}

@media screen and (min-width: 950px) and (max-width: 991px) {
  #navbarNav {
    margin-left: 571px;
    width: 32%;

    .btn-auth {
      width: 115px;
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 950px) {
  #navbarNav {
    margin-left: 550px;
    width: 34%;

    .btn-auth {
      width: 115px;
    }
  }
}

@media screen and (min-width: 850px) and (max-width: 900px) {
  #navbarNav {
    margin-left: 472px;
    width: 34%;

    .btn-auth {
      width: 115px;
    }
  }
}

@media screen and (min-width: 750px) and (max-width: 850px) {
  #navbarNav {
    margin-left: 425px;
    width: 40%;

    .btn-auth {
      width: 115px;
    }
  }
}

@media screen and (min-width: 650px) and (max-width: 750px) {
  #navbarNav {
    margin-left: 354px;
    width: 42%;

    .btn-auth {
      width: 105px;
    }
  }
}

@media screen and (min-width: 550px) and (max-width: 650px) {
  #navbarNav {
    margin-left: 268px;
    width: 48%;

    .btn-auth {
      width: 105px;
    }
  }
}

@media screen and (min-width: 450px) and (max-width: 550px) {
  #navbarNav {
    margin-left: 183px;
    width: 58%;

    .btn-auth {
      width: 105px;
    }
  }
}

@media screen and (min-width: 400px) and (max-width: 450px) {
  #navbarNav {
    margin-left: 127px;
    width: 67%;

    .btn-auth {
      width: 110px;
    }
  }
}

@media screen and (min-width: 350px) and (max-width: 400px) {
  #navbarNav {
    margin-left: 70px;
    width: 76%;

    .btn-auth {
      width: 108px;
    }
  }
}

@media screen and (min-width: 310px) and (max-width: 350px) {
  #navbarNav {
    margin-left: 14px;
    width: 90%;

    .btn-auth {
      width: 110px;
    }
  }
}

@media screen and (min-width: 278px) and (max-width: 310px) {
  #navbarNav {
   
    margin-left: 12px;
    width: 96%;

    .btn-auth {
      width: 83px;
    }
  }
}

@media screen and (min-width: 230px) and (max-width: 278px) {
  #navbarNav {
    margin-left: 40px;
    width: 79%;

    .btn-auth {
      width: 70px;
    }
  }
}

@media only screen and(max-width: 230px) {
  #navbarNav {
    margin-left: 0px;
    width: 100%;
  }
}